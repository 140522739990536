import {html, nothing} from 'lit-html';
import BElement from '../../BElement.js';
import '../css/Home.css';
import {getMatrixClient} from '../../matrix/control/MatrixClient.js';
import {Router} from '@vaadin/router';
import {addErrorMessage} from '../../messages/control/MessagesControl.js';

class Invitations extends BElement {
    extractState(state) {
        return {
            matrix: state.matrix,
        }
    }

    view() {
        if (this.state.matrix.invitations.length === 0) {
            return nothing;
        }

        return html`
            <div class='card'>
                <div class='content-area'>
                    <div class='inner'>
                        <h3>Invitations</h3>
                        <table>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Creator</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            ${this.state.matrix.invitations.map(e => html`
                                <tr>
                                    <td>${e.room_name}</td>
                                    <td>${e.room_creator}</td>
                                    <td>
                                        <kosyma-button
                                                identifier=${'join_' + e.room_id}
                                                label='Join'
                                                @click=${() => this.onJoin(e.room_id)}
                                        ></kosyma-button>
                                        <kosyma-button
                                                identifier=${'reject_' + e.room_id}
                                                label='Reject'
                                                @click=${() => this.onReject(e.room_id)}
                                        ></kosyma-button>
                                    </td>
                                </tr>
                            `)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>`
    }

    onJoin = roomId => {
        getMatrixClient().joinRoom(roomId).then(res => {
            Router.go('/messenger/rooms/' + roomId);
        }).catch(err => {
            addErrorMessage('Login failed');
            console.error(err)
        });
    }

    onReject = roomId => {
        getMatrixClient().leave(roomId).then(res => {

        }).catch(err => {
            addErrorMessage('Login failed');
            console.error(err)
        });
    }
}

customElements.define('kosyma-home-invitations', Invitations);
