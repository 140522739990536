import {html} from 'lit-html';
import {Router} from '@vaadin/router';
import BElement from '../../BElement.js';

class OrgAdminMenu extends BElement {
    extractState(state) {
        return {
            orgAdmin: state.orgAdmin
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>OrgAdmin</h2>
                    <table>
                        <tbody>
                        <tr>
                            <kosyma-button
                                    identifier='list'
                                    label='Users'
                                    @click=${() => Router.go('/messenger/orgadmin/users/list')}
                            ></kosyma-button>
                        </tr>
                        <tr>
                            <kosyma-button
                                    identifier='fhir'
                                    label='Fhir'
                                    @click=${() => Router.go('/messenger/orgadmin/fhir/search')}
                            ></kosyma-button>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        `
    }
}

customElements.define('kosyma-orgadmin-menu', OrgAdminMenu);
