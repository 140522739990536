import {html} from 'lit-html'
import BElement from '../../BElement.js'
import {setPasswordLoginState} from '../control/RegisterControl.js';
import {isUserAvailable, registerByPassword} from '../control/RegisterByPasswordClient.js';
import {Router} from '@vaadin/router';
import {addErrorMessage, addInformationMessage} from '../../messages/control/MessagesControl.js';


class RegisterByPassword extends BElement {
    extractState(state) {
        return {
            base_url: state.matrix.base_url,
            password: state.register.password
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <h2>Register new User</h2>
                    <h3>${this.state.base_url}</h3>
                    <kosyma-input-text
                            identifier='user'
                            label='User'
                            name='user'
                            autofocus='true'
                            value=${this.state.password.user}
                            @keyup=${(e) => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-input-text
                            identifier='password'
                            label='Password'
                            name='password'
                            type='password'
                            value=${this.state.password.password}
                            @keyup=${(e) => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-input-text
                            identifier='password_repeat'
                            label='Password Repeat'
                            name='password_repeat'
                            type='password'
                            value=${this.state.password.password_repeat}
                            @keyup=${(e) => this.onChangeValue(e)}
                    >
                    </kosyma-input-text>
                    <kosyma-button
                            identifier='register'
                            label='Register'
                            @click=${this.onRegister}
                    ></kosyma-button>
                </div>

            </div>
        `
    }

    onChangeValue = ({target: {name, value}}) => {
        setPasswordLoginState({name, value})
    }

    onRegister = async () => {
        if (!this.state.password.user ||
            !this.state.password.password || !this.state.password.password_repeat) {
            addErrorMessage('Invalid User/Password');
            return;
        }

        if (this.state.password.password !== this.state.password.password_repeat) {
            addErrorMessage('Password doesn\'t match');
            return;
        }

        const available = await isUserAvailable(this.state.base_url, this.state.password.user);
        if (!available) {
            addErrorMessage(this.state.password.user + ' already in use');
            return;
        }

        registerByPassword(this.state.base_url, {
            username: this.state.password.user,
            password: this.state.password.password,
            initial_device_display_name: 'UI',
        }).then(async res => {
            Router.go('/login/');
            addInformationMessage('User successfully registered!');
        }).catch(err => {
            if (err.httpStatus !== 401) {
                addErrorMessage(err);
            }
        });
    }
}

customElements.define('kosyma-register-password', RegisterByPassword);
