import {html} from 'lit-html';
import BElement from '../../BElement.js';
import {setFhirSearchValueState} from '../control/OrgAdminControl.js';


class OrgAdminFhirSearch extends BElement {
    connectedCallback() {
        super.connectedCallback();
        this.addEventListener('debounced-input', this.onDebouncedInput);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.removeEventListener('debounced-input', this.onDebouncedInput);
    }

    extractState(state) {
        return {
            matrix: state.matrix,
            fhir_search: state.orgAdmin.fhir_search
        }
    }

    view() {
        return html`
            <div class='content-area'>
                <div class='inner'>
                    <div class='inner'>
                        <h2>FHIR Search</h2>
                    </div>
                    <div class='inner'>
                        <div>
                            <kosyma-input-text
                                    identifier='filter'
                                    label='Filter'
                                    name='filter'
                                    autofocus='true'
                                    value=${this.state.fhir_search.filter}
                                    @input=${e => this.onInputChange(e)}
                            ></kosyma-input-text>
                        </div>
                    </div>
                </div>
            </div>
        `
    }


    onInputChange = ({target: {name, value}}) => {
        setFhirSearchValueState({name, value});
        this.debouncedUpdate(value);
    }

    onDebouncedInput = () => {
        console.log('debouncedUpdate');
        setFhirSearchValueState({name: 'results', value: []});
    }

    debounce = (func, wait) => {
        return (...args) => {
            clearTimeout(this.timeoutId);
            this.timeoutId = setTimeout(() => func.apply(this, args), wait);
        };
    }

    debouncedUpdate = this.debounce(value => {
        this.dispatchEvent(new CustomEvent('debounced-input', {
            detail: {value}
        }));
    }, 1000);
}

customElements.define('kosyma-orgadmin-fhir-search', OrgAdminFhirSearch);
