import {html} from 'lit-html';
import BElement from '../../BElement.js';

class FormattedJson extends BElement {
    static properties = {
        jsonData: {type: Object}
    };

    constructor() {
        super();
        this.jsonData = {};
    }

    static get observedAttributes() {
        return ['data-json'];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (name === 'data-json') {
            try {
                this.jsonData = JSON.parse(newValue); // JSON parsen und setzen
            } catch (e) {
                console.error('Invalid JSON in data-json attribute:', e);
            }
        }
    }

    view() {
        const formattedJson = this.formatJson(this.jsonData);
        return html`
            <div class='json-container' .innerHTML='${formattedJson}'></div>
        `;
    }

    formatJson(json) {
        const jsonString = JSON.stringify(json, null, 2);
        return jsonString.replace(
            /('(.*?)':)|('.*?')|(\b\d+\b)|\b(true|false|null)\b/g,
            (match, key, keyName, string, number, booleanOrNull) => {
                if (key) return `<span class='json-key'>${key}</span>`;
                if (string) return `<span class='json-string'>${string}</span>`;
                if (number) return `<span class='json-number'>${number}</span>`;
                if (booleanOrNull === 'true' || booleanOrNull === 'false') return `<span class='json-boolean'>${booleanOrNull}</span>`;
                if (booleanOrNull === 'null') return `<span class='json-null'>${booleanOrNull}</span>`;
                return match;
            }
        );
    }
}

customElements.define('kosyma-formatted-json', FormattedJson);