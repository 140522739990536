import {createReducer} from '@reduxjs/toolkit'
import {
    userEditAction,
    userEditValueAction,
    userEditPvsCustomValueAction,
    userListAction, fhirSearchValueAction
} from '../control/OrgAdminControl.js'

const initialState = {
    user_list: [],
    user_edit: null,
    fhir_search: {
        filter: '',
        result: []
    },
}

export const orgAdmin = createReducer(initialState, (builder) => {
    builder.addCase(userListAction, (state, {payload}) => {
        state.user_list = payload;
    }).addCase(userEditAction, (state, {payload}) => {
        state.user_edit = payload;
    }).addCase(userEditValueAction, (state, {payload}) => {
        state.user_edit[payload.name] = payload.value;
    }).addCase(userEditPvsCustomValueAction, (state, {payload}) => {
        state.user_edit.pvs_custom[payload.name] = payload.value;
    }).addCase(fhirSearchValueAction, (state, {payload}) => {
        state.fhir_search[payload.name] = payload.value;
    });
});
